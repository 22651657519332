@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&family=Saira+Stencil+One&family=Bebas+Neue&display=swap');

body {
  background-color: black;
  background-size: cover;
  background-image: url("./images/backgrounds/web bg.png");
  background-repeat: no-repeat;
  font-family: 'Roboto Condensed', sans-serif;
  color: #4c5049;
  padding: 0;
  margin: 0;
  display:flex;
  flex-flow: column wrap;
  justify-content:center;
}

.title-font {
  font-family: "Saira Stencil One", sans-serif;
}

a {
  color: #bf4a11;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.announcement-color {
  color: #bf4a11;
}

.announcement {
  font-family: 'Bebas Neue', cursive;
  font-size: 32px;
}

.retailers-list {
  padding: 0;
  margin-block: unset;
}

.retailers-list li {
  list-style: none;
  display: block;
}

.text {
  font-size: 11pt;
}

.textContent {
  background-color: rgba(0,0,0,.7);
  text-align: center;
  text-transform: none;
  padding-top: 1vw;
  padding-bottom: 1vw;
  z-index: 200;
}

.static {
  background-image: url("./images/static.png");
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 100;
}

.container {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}

.playerContainer {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
}

.playerContainer iframe {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  border: 1px solid #363636;
  width: 200px;
  height: 200px;
  filter: drop-shadow(2px 2px 5px rgb(0, 0, 0));
}

.logo {
  text-transform: uppercase;
  font-size: 20vw;
  line-height: 18vw;
  margin-top: 6vw;
  color: rgba(155, 171, 144, .18);
  text-align: center;
  vertical-align: top;
  position: relative;
  z-index: 80;
}

.logo .x {
}

.logo span {
  padding-left: 20px;
  padding-right: 20px;
}

.sourcetag {
  position: relative;
  z-index: 300;
  text-transform: uppercase;
  font-size: 1.2vw;
  color: rgba(147, 123, 102, 0.5);
  margin-top: -4.8vw;
  margin-bottom: 2vw;
}

#bg-canvas {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}
